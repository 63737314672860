import _assert from "assert";
import _MomoParser from "./Momo-Parser.js";
import _util from "util";
import _url from "url";

var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

var exports = {};

//
// Momo-Job.js — Momo
// today is 7/25/12, it is now 3:25 PM
// created by TotenDev
// see LICENSE for details.
//
var assert = _assert,
    parser = _MomoParser(),
    util = _util,
    url = _url;
/**
* Initialize Momo-Job function
**/


exports = function (momoLine) {
  return new MomoJob(momoLine);
};

function MomoJob(momoLine) {
  (this || _global).initLine = momoLine; //Do some formating job

  if ((this || _global).initLine.substr(0, 1) == " ") {
    (this || _global).initLine = (this || _global).initLine.substr(1, (this || _global).initLine.length - 1);
  } //Checks


  if (this.isCurrentInitLineValid() == false) {
    assert.ok(false, "Initial MomoJob line is not valid, something is missing");
  }
}

;
/** Validate Line **/

MomoJob.prototype.isCurrentInitLineValid = function isCurrentInitLineValid() {
  var commands = (this || _global).initLine.split(" ");

  if (commands.length > 5) {
    for (var i = 0; i < commands.length; i++) {
      switch (i) {
        case 0:
          /*Minutes*/
          {
            var response = parser.commandAllowedValues(commands[i], 59);

            if (typeof response == "boolean" && response == false) {
              return false;
              /*ABORT*/
            } else {
              (this || _global).minutesValue = response;
            }
          }
          break;

        case 1:
          /*Hours*/
          {
            var response = parser.commandAllowedValues(commands[i], 23);

            if (typeof response == "boolean" && response == false) {
              return false;
              /*ABORT*/
            } else {
              (this || _global).hoursValue = response;
            }
          }
          break;

        case 2:
          /*Days of Month*/
          {
            var response = parser.commandAllowedValues(commands[i], 31);

            if (typeof response == "boolean" && response == false) {
              return false;
              /*ABORT*/
            } else {
              (this || _global).monthDaysValue = response;
            }
          }
          break;

        case 3:
          /*Months*/
          {
            var response = parser.commandAllowedValues(commands[i], 12);

            if (typeof response == "boolean" && response == false) {
              return false;
              /*ABORT*/
            } else {
              (this || _global).monthsValue = response;
            }
          }
          break;

        case 4:
          /*Days of Week*/
          {
            var response = parser.commandAllowedValues(commands[i], 6);

            if (typeof response == "boolean" && response == false) {
              return false;
              /*ABORT*/
            } else {
              (this || _global).daysOfWeekValue = response;
            }
          }
          break;

        case 5:
          /*Hook URL*/
          {
            if (commands[i] && commands[i].length > 0) {
              (this || _global).hookURL = url.parse(commands[i]);
            } else {
              return false;
              /*ABORT*/
            }
          }
          break;

        default:
          break;
      }
    }

    return true;
    /*valid condition*/
  } else if (commands.length == 2) {
    //Auxs
    var zero = new Array();
    zero.push(0);
    var one = new Array();
    one.push(1); //Check for valid url

    if (commands[1] && commands[1].length > 0) {
      //Check for match special keywords
      if (commands[0].match("@yearly") || commands[0].match("@annually")) {
        (this || _global).minutesValue = zero;
        (this || _global).hoursValue = zero;
        (this || _global).monthDaysValue = one;
        (this || _global).monthsValue = one;
        (this || _global).daysOfWeekValue = "*";
      } else if (commands[0].match("@monthly")) {
        (this || _global).minutesValue = zero;
        (this || _global).hoursValue = zero;
        (this || _global).monthDaysValue = one;
        (this || _global).monthsValue = "*";
        (this || _global).daysOfWeekValue = "*";
      } else if (commands[0].match("@weekly")) {
        (this || _global).minutesValue = zero;
        (this || _global).hoursValue = zero;
        (this || _global).monthDaysValue = "*";
        (this || _global).monthsValue = "*";
        (this || _global).daysOfWeekValue = zero;
      } else if (commands[0].match("@daily")) {
        (this || _global).minutesValue = zero;
        (this || _global).hoursValue = zero;
        (this || _global).monthDaysValue = "*";
        (this || _global).monthsValue = "*";
        (this || _global).daysOfWeekValue = "*";
      } else if (commands[0].match("@hourly")) {
        (this || _global).minutesValue = zero;
        (this || _global).hoursValue = "*";
        (this || _global).monthDaysValue = "*";
        (this || _global).monthsValue = "*";
        (this || _global).daysOfWeekValue = "*";
      } else {
        return false;
      } //Valid condition


      (this || _global).hookURL = url.parse(commands[1]);
      return true;
    }
  }

  return false;
};
/** Check if should execute or not **/


MomoJob.prototype.shouldExecuteOnDate = function shouldExecuteOnDate(date) {
  //Count needed aprove commands 
  var approvePending = 0,
      deactivateds = 0;

  if ((this || _global).minutesValue != "*") {
    approvePending++;
  } else {
    deactivateds++;
  }

  if ((this || _global).hoursValue != "*") {
    approvePending++;
  } else {
    deactivateds++;
  }

  if ((this || _global).monthDaysValue != "*") {
    approvePending++;
  } else {
    deactivateds++;
  }

  if ((this || _global).monthsValue != "*") {
    approvePending++;
  } else {
    deactivateds++;
  }

  if ((this || _global).daysOfWeekValue != "*") {
    approvePending++;
  } else {
    deactivateds++;
  } //Check for minutes


  if ((this || _global).minutesValue.indexOf(date.getMinutes()) != -1) {
    approvePending--;
  } //Check for hours


  if ((this || _global).hoursValue.indexOf(date.getHours()) != -1) {
    approvePending--;
  } //Check for day of month


  if ((this || _global).monthDaysValue.indexOf(date.getDate()) != -1) {
    approvePending--;
  } //Check for months


  if ((this || _global).monthsValue.indexOf(date.getMonth()) != -1) {
    approvePending--;
  } //Check for days of week


  if ((this || _global).daysOfWeekValue.indexOf(date.getDay()) != -1) {
    approvePending--;
  }

  return approvePending == 0 && deactivateds != 5;
};
/** Execution URL **/


MomoJob.prototype.executionURL = function executionURL() {
  return (this || _global).hookURL;
};

export default exports;